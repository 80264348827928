import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core/compositions/notification';

export const useUpdateOrderTerms = (errorCallback) => {
  const { error } = useNotification();

  const { mutate, loading, onError, onDone } = useMutation(UPDATE_ORDER_TERM);

  onError((err) => {
    console.error('useUpdateOrderTerms', err);
    error();
    errorCallback();
  });

  return {
    updateOrderTerms: mutate,
    loading,
    onDone,
  };
};

const UPDATE_ORDER_TERM = gql`
  mutation UpdateOrderTerms($orderTermId: ID, $updatePayload: UpdateOrderTerms) {
    updateOrderTerms(orderTermId: $orderTermId, updatePayload: $updatePayload) {
      success
    }
  }
`;

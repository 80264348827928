var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"visible":true,"custom-class":"rounded","width":"50rem"},on:{"close":_vm.onCancel}},[_c('h2',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(_vm.$t('orderTerms.title')))]),_c('el-form',{ref:"form",attrs:{"model":_vm.formValues,"rules":_vm.rules}},[_c('div',{staticClass:"order-term-form"},[_c('div',{staticClass:"contact-info"},[_c('div',{staticClass:"contact-info-title"},[_vm._v(" "+_vm._s(_vm.$t('orderTerms.contactInfo.title'))+" ")]),_c('el-form-item',{staticClass:"contact-methods",attrs:{"prop":"contactInfo"}},_vm._l((_vm.formValues.contactInfo),function(contact,index){return _c('div',{key:`contact-method-${index}`},[_c('div',{key:index,staticClass:"contact-method",class:{ error: _vm.contactOptionsError }},[_c('div',{staticClass:"checkbox"},[_c('el-checkbox',{on:{"change":function($event){return _vm.handleCheckboxChange(contact, index)}},model:{value:(contact.selected),callback:function ($$v) {_vm.$set(contact, "selected", $$v)},expression:"contact.selected"}},[_vm._v(" "+_vm._s(_vm.$t(`orderTerms.contactInfo.${contact.method}`))+" ")])],1),(_vm.isContactInfoWithValue(contact.method))?_c('div',{staticClass:"input"},[(contact.selected)?_c('el-form-item',{attrs:{"prop":`contactInfo[${index}].address`,"rules":{
                    validator: _vm.validateEmpty,
                    trigger: 'change',
                  }}},[_c('div',{staticClass:"d-flex"},[_c('el-input',{model:{value:(contact.address),callback:function ($$v) {_vm.$set(contact, "address", $$v)},expression:"contact.address"}}),_c('el-button',{staticClass:"add-button",style:({
                        visibility: _vm.isTheLastContactFromTheSameMethod(contact.method, index) ? 'visible' : 'hidden',
                      }),attrs:{"type":"text","icon":"el-icon-plus"},on:{"click":function($event){return _vm.addContactMethod(contact.method)}}},[_vm._v(" "+_vm._s(_vm.$t(`orderTerms.contactInfo.${contact.method}AddBtn`))+" ")])],1)]):_vm._e()],1):_vm._e()])])}),0)],1),_c('div',{staticClass:"schedule",class:{ error: _vm.dayOfWeekError }},[_c('div',{staticClass:"schedule-title"},[_vm._v(" "+_vm._s(_vm.$t('orderTerms.schedule.title'))+" ")]),_c('el-form-item',{attrs:{"prop":"schedule"}},[_c('div',{staticClass:"day-picker"},_vm._l((_vm.weekDays),function(day,index){return _c('div',{key:`day-${index}`},[_c('div',{staticClass:"day-of-week-checkbox",class:{ selected: _vm.formValues.schedule[day].selected, error: _vm.dayOfWeekError },on:{"click":() => _vm.onDayOfWeekClicked(day)}},[_vm._v(" "+_vm._s(_vm.$t(`orderTerms.schedule.weekDays.short.${day}`))+" ")])])}),0)]),_c('div',{staticClass:"schedule-pickers"},[(_vm.selectedDays.length > 0)?_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"day"}),_c('div',{staticClass:"delivery-arrival"},[_vm._v(_vm._s(_vm.$t('orderTerms.schedule.deliveryArrivalTime')))]),_c('div',{staticClass:"order-cut-off"},[_vm._v(_vm._s(_vm.$t('orderTerms.schedule.orderCutOffTime')))])]):_vm._e(),_vm._l((_vm.selectedDays),function(selectedDay,index){return _c('div',{key:`selected-day-${index}`,staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"day"},[_vm._v(" "+_vm._s(_vm.$t(`orderTerms.schedule.weekDays.long.${selectedDay}`))+" ")]),_c('div',{staticClass:"delivery-arrival"},[_c('div',{staticClass:"hour-select"},[_c('el-form-item',{attrs:{"prop":`schedule.${selectedDay}.value.deliveryArrivalTime.timeOfDay`,"rules":{
                    validator: _vm.validateEmpty,
                    trigger: 'change',
                  }}},[_c('el-select',{staticClass:"arrival-time-select",attrs:{"placeholder":_vm.$t('orderTerms.schedule.hour'),"popper-append-to-body":false},on:{"input":(time) => _vm.onDeliveryArrivalTimeSelect(selectedDay, time)},model:{value:(_vm.formValues.schedule[selectedDay].value.deliveryArrivalTime.timeOfDay),callback:function ($$v) {_vm.$set(_vm.formValues.schedule[selectedDay].value.deliveryArrivalTime, "timeOfDay", $$v)},expression:"formValues.schedule[selectedDay].value.deliveryArrivalTime.timeOfDay"}},_vm._l((_vm.hoursList),function(hour,hIndex){return _c('el-option',{key:`hour-${hIndex}`,attrs:{"label":hour,"value":hour}})}),1)],1)],1)]),_c('div',{staticClass:"order-cut-off"},[_c('el-form-item',{attrs:{"prop":`schedule.${selectedDay}.value.orderCutOffTime.dayOfWeek`,"rules":{
                  validator: _vm.validateEmpty,
                  trigger: 'change',
                }}},[_c('el-select',{staticClass:"day-select",attrs:{"placeholder":_vm.$t('orderTerms.schedule.day'),"popper-append-to-body":false},model:{value:(_vm.formValues.schedule[selectedDay].value.orderCutOffTime.dayOfWeek),callback:function ($$v) {_vm.$set(_vm.formValues.schedule[selectedDay].value.orderCutOffTime, "dayOfWeek", $$v)},expression:"formValues.schedule[selectedDay].value.orderCutOffTime.dayOfWeek"}},_vm._l((_vm.mappedWeekDays),function(weekDay,wIndex){return _c('el-option',{key:`wk-${wIndex}`,attrs:{"label":weekDay.label,"value":weekDay.value}})}),1)],1),_c('el-form-item',{attrs:{"prop":`schedule.${selectedDay}.value.orderCutOffTime.timeOfDay`,"rules":{
                  validator: _vm.validateEmpty,
                  trigger: 'change',
                }}},[_c('el-select',{attrs:{"placeholder":_vm.$t('orderTerms.schedule.hour'),"popper-append-to-body":false},model:{value:(_vm.formValues.schedule[selectedDay].value.orderCutOffTime.timeOfDay),callback:function ($$v) {_vm.$set(_vm.formValues.schedule[selectedDay].value.orderCutOffTime, "timeOfDay", $$v)},expression:"formValues.schedule[selectedDay].value.orderCutOffTime.timeOfDay"}},_vm._l((_vm.hoursList),function(hour,hIndex){return _c('el-option',{key:`hour-${hIndex}`,attrs:{"label":hour,"value":hour}})}),1)],1)],1)])})],2)],1),_c('div',{staticClass:"extra-settings"},[_c('div',{staticClass:"extra-settings-title"},[_vm._v(" "+_vm._s(_vm.$t('orderTerms.extraSettings.title'))+" ")]),_c('el-form-item',{attrs:{"prop":"minimumOrderAmount","rules":{
            validator: _vm.validateMinimumOrderAmount,
            message: _vm.$t('orderTerms.extraSettings.minimumOrderAmountError'),
          }}},[_c('div',{staticClass:"min-order-amount"},[_c('div',{staticClass:"checkbox"},[_c('el-checkbox',{on:{"change":_vm.onMinOrderAmountSelectedChange},model:{value:(_vm.formValues.minimumOrderAmount.selected),callback:function ($$v) {_vm.$set(_vm.formValues.minimumOrderAmount, "selected", $$v)},expression:"formValues.minimumOrderAmount.selected"}},[_vm._v(" "+_vm._s(_vm.$t('orderTerms.extraSettings.minimumOrderAmount'))+" ")])],1),_c('div',{staticClass:"money-input",class:{ error: _vm.minimumOrderAmountError }},[(_vm.formValues.minimumOrderAmount.selected)?_c('el-form-item',{attrs:{"prop":"minimumOrderAmount.value"}},[_c('el-input',{attrs:{"type":"number"},on:{"input":_vm.onMinOrderAmountChange},model:{value:(_vm.formValues.minimumOrderAmount.value),callback:function ($$v) {_vm.$set(_vm.formValues.minimumOrderAmount, "value", $$v)},expression:"formValues.minimumOrderAmount.value"}},[_c('template',{slot:_vm.$direction === 'rtl' ? 'append' : 'prepend'},[_vm._v(_vm._s(_vm.currencySymbol))])],2)],1):_vm._e()],1)])])],1)])]),_c('span',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{attrs:{"type":"secondary"},on:{"click":_vm.onCancel}},[_vm._v(_vm._s(_vm.$t('commons.cancel')))]),_c('Button',{attrs:{"type":"primary","loading":_vm.submitLoading},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t('commons.save')))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { gql } from '@apollo/client/core';

import { useQuery, useResult } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

const ORDER_TERMS = gql`
  query orderTerms($businessId: ID!, $supplierId: ID, $first: Int, $after: Int) {
    orderTerms(businessId: $businessId, supplierId: $supplierId, first: $first, after: $after) {
      totalCount
      nodes {
        id
        businessId
        supplierId
        supplier {
          id
          name
        }
        schedules {
          id
          deliveryArrivalTime {
            dayOfWeek
            timeOfDay
          }
          orderCutOffTime {
            dayOfWeek
            timeOfDay
          }
        }
        contactInfo {
          id
          method
          address
        }
        minimumOrderAmount
      }
    }
  }
`;

export function useSupplierOrderTerms(variables, enabledCondition) {
  const { error } = useNotification();

  const { result, loading, onError, refetch, onResult } = useQuery(ORDER_TERMS, variables, {
    enabled: enabledCondition,
  });

  const supplierOrderTerms = useResult(result, { totalCount: 0, nodes: [] });

  onError((err) => {
    console.error('useSupplierOrderTerms', err);
    error();
  });

  return {
    supplierOrderTerms,
    loading,
    refetch,
    onResult,
  };
}

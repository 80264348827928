<template>
  <el-dialog :visible="true" custom-class="rounded" width="50rem" @close="onCancel">
    <h2 slot="title">{{ $t('orderTerms.title') }}</h2>
    <el-form ref="form" :model="formValues" :rules="rules">
      <div class="order-term-form">
        <div class="contact-info">
          <div class="contact-info-title">
            {{ $t('orderTerms.contactInfo.title') }}
          </div>
          <el-form-item class="contact-methods" prop="contactInfo">
            <div v-for="(contact, index) in formValues.contactInfo" :key="`contact-method-${index}`">
              <div :key="index" class="contact-method" :class="{ error: contactOptionsError }">
                <div class="checkbox">
                  <el-checkbox v-model="contact.selected" @change="handleCheckboxChange(contact, index)">
                    {{ $t(`orderTerms.contactInfo.${contact.method}`) }}
                  </el-checkbox>
                </div>
                <div v-if="isContactInfoWithValue(contact.method)" class="input">
                  <el-form-item
                    v-if="contact.selected"
                    :prop="`contactInfo[${index}].address`"
                    :rules="{
                      validator: validateEmpty,
                      trigger: 'change',
                    }"
                  >
                    <div class="d-flex">
                      <el-input v-model="contact.address" />
                      <el-button
                        :style="{
                          visibility: isTheLastContactFromTheSameMethod(contact.method, index) ? 'visible' : 'hidden',
                        }"
                        class="add-button"
                        type="text"
                        icon="el-icon-plus"
                        @click="addContactMethod(contact.method)"
                      >
                        {{ $t(`orderTerms.contactInfo.${contact.method}AddBtn`) }}
                      </el-button>
                    </div>
                  </el-form-item>
                </div>
              </div>
            </div>
          </el-form-item>
        </div>
        <div class="schedule" :class="{ error: dayOfWeekError }">
          <div class="schedule-title">
            {{ $t('orderTerms.schedule.title') }}
          </div>
          <el-form-item prop="schedule">
            <div class="day-picker">
              <div v-for="(day, index) in weekDays" :key="`day-${index}`">
                <div
                  class="day-of-week-checkbox"
                  :class="{ selected: formValues.schedule[day].selected, error: dayOfWeekError }"
                  @click="() => onDayOfWeekClicked(day)"
                >
                  {{ $t(`orderTerms.schedule.weekDays.short.${day}`) }}
                </div>
              </div>
            </div>
          </el-form-item>
          <div class="schedule-pickers">
            <div v-if="selectedDays.length > 0" class="d-flex align-items-center">
              <div class="day"></div>
              <div class="delivery-arrival">{{ $t('orderTerms.schedule.deliveryArrivalTime') }}</div>
              <div class="order-cut-off">{{ $t('orderTerms.schedule.orderCutOffTime') }}</div>
            </div>
            <div
              v-for="(selectedDay, index) in selectedDays"
              :key="`selected-day-${index}`"
              class="d-flex align-items-center"
            >
              <div class="day">
                {{ $t(`orderTerms.schedule.weekDays.long.${selectedDay}`) }}
              </div>
              <div class="delivery-arrival">
                <div class="hour-select">
                  <el-form-item
                    :prop="`schedule.${selectedDay}.value.deliveryArrivalTime.timeOfDay`"
                    :rules="{
                      validator: validateEmpty,
                      trigger: 'change',
                    }"
                  >
                    <el-select
                      v-model="formValues.schedule[selectedDay].value.deliveryArrivalTime.timeOfDay"
                      :placeholder="$t('orderTerms.schedule.hour')"
                      :popper-append-to-body="false"
                      class="arrival-time-select"
                      @input="(time) => onDeliveryArrivalTimeSelect(selectedDay, time)"
                    >
                      <el-option
                        v-for="(hour, hIndex) in hoursList"
                        :key="`hour-${hIndex}`"
                        :label="hour"
                        :value="hour"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="order-cut-off">
                <el-form-item
                  :prop="`schedule.${selectedDay}.value.orderCutOffTime.dayOfWeek`"
                  :rules="{
                    validator: validateEmpty,
                    trigger: 'change',
                  }"
                >
                  <el-select
                    v-model="formValues.schedule[selectedDay].value.orderCutOffTime.dayOfWeek"
                    :placeholder="$t('orderTerms.schedule.day')"
                    :popper-append-to-body="false"
                    class="day-select"
                  >
                    <el-option
                      v-for="(weekDay, wIndex) in mappedWeekDays"
                      :key="`wk-${wIndex}`"
                      :label="weekDay.label"
                      :value="weekDay.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  :prop="`schedule.${selectedDay}.value.orderCutOffTime.timeOfDay`"
                  :rules="{
                    validator: validateEmpty,
                    trigger: 'change',
                  }"
                >
                  <el-select
                    v-model="formValues.schedule[selectedDay].value.orderCutOffTime.timeOfDay"
                    :placeholder="$t('orderTerms.schedule.hour')"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="(hour, hIndex) in hoursList"
                      :key="`hour-${hIndex}`"
                      :label="hour"
                      :value="hour"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="extra-settings">
          <div class="extra-settings-title">
            {{ $t('orderTerms.extraSettings.title') }}
          </div>
          <el-form-item
            prop="minimumOrderAmount"
            :rules="{
              validator: validateMinimumOrderAmount,
              message: $t('orderTerms.extraSettings.minimumOrderAmountError'),
            }"
          >
            <div class="min-order-amount">
              <div class="checkbox">
                <el-checkbox v-model="formValues.minimumOrderAmount.selected" @change="onMinOrderAmountSelectedChange">
                  {{ $t('orderTerms.extraSettings.minimumOrderAmount') }}
                </el-checkbox>
              </div>
              <div class="money-input" :class="{ error: minimumOrderAmountError }">
                <el-form-item v-if="formValues.minimumOrderAmount.selected" prop="minimumOrderAmount.value">
                  <el-input v-model="formValues.minimumOrderAmount.value" type="number" @input="onMinOrderAmountChange">
                    <template :slot="$direction === 'rtl' ? 'append' : 'prepend'">{{ currencySymbol }}</template>
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <span slot="footer">
      <Button type="secondary" @click="onCancel">{{ $t('commons.cancel') }}</Button>
      <Button type="primary" :loading="submitLoading" @click="onSubmit">{{ $t('commons.save') }}</Button>
    </span>
  </el-dialog>
</template>
<script>
import { ref, reactive, computed, getCurrentInstance, toRefs, watch } from 'vue';

import { useCurrency } from '@/locale/useCurrency';
import { Button } from '@/modules/core';
import i18n from '@/imports/startup/client/i18n';
import { orderBy } from 'lodash';

export const CONTACT_OPTIONS = {
  email: 'email',
  sms: 'sms',
  integration: 'integration',
};

export default {
  name: 'OrderTermsModal',
  components: { Button },
  props: { data: { type: Object, default: () => ({}) }, submitLoading: { type: Boolean, default: false } },
  emits: ['on-cancel', 'on-submit'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const { currencyFormat } = useCurrency();
    const currencySymbol = computed(() => {
      return (0).toLocaleString(i18n.locale, currencyFormat.value).replace(/\d./g, '').trim();
    });
    const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const hoursList = [];
    for (let i = 0; i < 24; i++) {
      if (i < 10) {
        hoursList.push(`0${i}:00`);
      } else {
        hoursList.push(`${i}:00`);
      }
    }
    const mappedWeekDays = weekDays.map((day) => {
      return {
        label: root.$t(`orderTerms.schedule.weekDays.long.${day}`),
        value: day,
      };
    });

    const isContactInfoWithValue = (method) => [CONTACT_OPTIONS.email, CONTACT_OPTIONS.sms].includes(method);

    const getInitialFormValues = (data) => {
      const defaultContacts = [
        { method: CONTACT_OPTIONS.email, address: '', selected: false, id: null },
        { method: CONTACT_OPTIONS.sms, address: '', selected: false, id: null },
        { method: CONTACT_OPTIONS.integration, address: '', selected: false, id: null },
      ];

      const existingContacts =
        data.contactInfo?.map((contact) => ({
          ...contact,
          selected: true,
        })) || [];

      const methodsInData = existingContacts.map((contact) => contact.method);
      const missingDefaults = defaultContacts.filter(
        (defaultContact) => !methodsInData.includes(defaultContact.method)
      );

      const mergedContacts = [...existingContacts, ...missingDefaults];

      const nonIntegrationContacts = mergedContacts.filter((contact) => contact.method !== CONTACT_OPTIONS.integration);
      const integrationContacts = mergedContacts.filter((contact) => contact.method === CONTACT_OPTIONS.integration);

      const formValuesToUpdate = {
        contactInfo: [...orderBy(nonIntegrationContacts, ['method']), ...integrationContacts],
        schedule: {},
        minimumOrderAmount: { selected: false, value: undefined },
      };

      const hasData = Object.keys(data).length > 0;

      weekDays.forEach((weekDay) => {
        const relevantWeekDay = hasData
          ? data.schedules.find(({ deliveryArrivalTime: { dayOfWeek } }) => dayOfWeek === weekDay)
          : undefined;
        formValuesToUpdate.schedule[weekDay] = {
          selected: relevantWeekDay ? true : false,
          value: relevantWeekDay
            ? {
                id: relevantWeekDay.id,
                deliveryArrivalTime: {
                  dayOfWeek: relevantWeekDay.deliveryArrivalTime.dayOfWeek,
                  timeOfDay: relevantWeekDay.deliveryArrivalTime.timeOfDay,
                },
                orderCutOffTime: {
                  dayOfWeek: relevantWeekDay.orderCutOffTime.dayOfWeek,
                  timeOfDay: relevantWeekDay.orderCutOffTime.timeOfDay,
                },
              }
            : {
                id: null,
                deliveryArrivalTime: {
                  dayOfWeek: null,
                  timeOfDay: null,
                },
                orderCutOffTime: {
                  dayOfWeek: null,
                  timeOfDay: null,
                },
              },
        };
      });

      if (hasData) {
        if (data.minimumOrderAmount && data.minimumOrderAmount > 0) {
          formValuesToUpdate.minimumOrderAmount.selected = true;
          formValuesToUpdate.minimumOrderAmount.value = data.minimumOrderAmount;
        }
      }

      return formValuesToUpdate;
    };

    const contactOptionsError = ref(false);
    const dayOfWeekError = ref(false);
    const minimumOrderAmountError = ref(false);
    const attemptedSubmit = ref(false);
    const canShowContactMethodError = ref(false);
    const canShowSelectedError = ref(false);
    const form = ref(null);
    const watchableData = toRefs(props).data;
    const formValues = reactive({ data: getInitialFormValues(watchableData.value) });
    const initialValues = ref(getInitialFormValues(watchableData.value));

    watch(
      watchableData,
      () => {
        formValues.data = getInitialFormValues(watchableData.value);
        initialValues.value = getInitialFormValues(watchableData.value);
      },
      { deep: true }
    );

    const isTheLastContactFromTheSameMethod = (method, index) => {
      const lastIndex = formValues.data.contactInfo?.reduce((acc, contact, idx) => {
        if (contact.selected && contact.method === method) {
          acc = idx;
        }
        return acc;
      }, -1);

      return lastIndex === index;
    };

    const selectedDays = computed(() => {
      const selected = [];
      weekDays.forEach((weekDay) => {
        if (formValues.data.schedule[weekDay].selected) selected.push(weekDay);
      });

      return selected;
    });

    const weekDayErrors = computed(() => {
      const errors = {};
      selectedDays.value.forEach((day) => {
        errors[day] = errors[day] || {};
        if (!formValues.data.schedule[day].value.deliveryArrivalTime.timeOfDay && attemptedSubmit.value) {
          errors[day].deliveryArrivalTime = true;
        } else {
          errors[day].deliveryArrivalTime = false;
        }
        if (!formValues.data.schedule[day].value.orderCutOffTime.timeOfDay && attemptedSubmit.value) {
          errors[day].orderCutOffTime = true;
        } else {
          errors[day].orderCutOffTime = false;
        }
        if (!formValues.data.schedule[day].value.orderCutOffTime.dayOfWeek && attemptedSubmit.value) {
          errors[day].orderCutOffDay = true;
        } else {
          errors[day].orderCutOffDay = false;
        }
      });
      return errors;
    });

    const hasWeekDayErrors = computed(() => {
      const weekDaysToCheck = Object.keys(weekDayErrors.value);
      let hasError = false;
      weekDaysToCheck.forEach((weekDay) => {
        const errorsToCheck = Object.keys(weekDayErrors.value[weekDay]);
        errorsToCheck.forEach((error) => {
          if (weekDayErrors.value[weekDay][error]) {
            hasError = true;
          }
        });
      });
      return hasError;
    });

    const atLeastOneContactOption = (rule, _, callback) => {
      if (!attemptedSubmit.value || !canShowContactMethodError.value) {
        contactOptionsError.value = false;
        callback();
        return;
      }

      if (formValues.data.contactInfo.every((contact) => !contact.selected)) {
        contactOptionsError.value = true;
        callback([new Error(rule.message)]);
      } else {
        canShowContactMethodError.value = false;
        contactOptionsError.value = false;
        callback();
      }
    };

    const hasDuplicationValueFromTheSameMethod = (rule, _, callback) => {
      const contactInfo = formValues.data.contactInfo;
      const hasDuplication = contactInfo.some((contact) => {
        return (
          contactInfo.filter(
            (c) =>
              c.method === contact.method && c.address?.toLocaleLowerCase() === contact.address?.toLocaleLowerCase()
          ).length > 1
        );
      });
      if (hasDuplication) {
        callback([new Error(rule.message)]);
      } else {
        callback();
      }
    };

    const atLeastOneOrderTime = (rule, _, callback) => {
      if (!attemptedSubmit.value) {
        dayOfWeekError.value = false;
        callback();
        return;
      }
      const allDays = Object.keys(formValues.data.schedule);
      let selectedAtLeastOne = false;
      allDays.forEach((day) => {
        if (formValues.data.schedule[day].selected) selectedAtLeastOne = true;
      });

      if (!selectedAtLeastOne) {
        dayOfWeekError.value = true;
        callback([new Error(rule.message)]);
      } else {
        dayOfWeekError.value = false;
        callback();
      }
    };

    const validateMinimumOrderAmount = (rule, minimumOrderAmount, callback) => {
      const valueToValidate = minimumOrderAmount.selected ? minimumOrderAmount.value : true;
      return validateEmpty(rule, valueToValidate, callback, minimumOrderAmountError);
    };

    const validateEmpty = (rule, val, callback, errorToUpdate = undefined) => {
      if (!val && canShowSelectedError.value) {
        if (errorToUpdate) {
          errorToUpdate.value = true;
        }
        callback([new Error(rule.message)]);
      } else {
        if (errorToUpdate) {
          errorToUpdate.value = false;
        }
        callback();
      }
    };

    const onMinOrderAmountSelectedChange = (selected) => {
      if (!selected) {
        formValues.data.minimumOrderAmount.value = null;
      }
    };

    const onMinOrderAmountChange = (value) => {
      formValues.data.minimumOrderAmount = {
        selected: true,
        value,
      };
      triggerMinAmountValidation();
    };

    const triggerMinAmountValidation = () => {
      form.value.validateField('minimumOrderAmount');
    };

    const rules = computed(() => ({
      contactInfo: formValues.data.contactInfo
        .map((contact) => ({
          validator: (_, __, callback) => {
            if (contact.selected) {
              if (contact.method === CONTACT_OPTIONS.email) {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailPattern.test(contact.address)) {
                  callback(new Error(root.$t('orderTerms.contactInfo.invalidEmail')));
                }
              } else if (contact.method === CONTACT_OPTIONS.sms) {
                const phonePattern = /^\+[0-9]{10,}$/;
                const allowedExtensions = ['+1', '+972'];
                const isValidExtension = allowedExtensions.some((ext) => contact.address.startsWith(ext));
                if (!phonePattern.test(contact.address) || !isValidExtension) {
                  callback(new Error(root.$t('orderTerms.contactInfo.invalidPhone')));
                }
              }
            }

            callback();
          },
          trigger: 'submit',
        }))
        .concat([
          {
            validator: atLeastOneContactOption,
            trigger: 'submit',
            message: root.$t('orderTerms.contactInfo.atLeastOne'),
          },
          {
            validator: hasDuplicationValueFromTheSameMethod,
            trigger: 'submit',
            message: root.$t('orderTerms.contactInfo.duplicationMethodWithTheSameValue'),
          },
        ]),
      schedule: [
        {
          validator: atLeastOneOrderTime,
          trigger: 'submit',
          message: root.$t('orderTerms.schedule.atLeastOne'),
        },
      ],
    }));

    const onCancel = () => {
      dayOfWeekError.value = false;
      contactOptionsError.value = false;
      attemptedSubmit.value = false;
      Object.assign(formValues.data, getInitialFormValues(watchableData.value));
      emit('on-cancel');
    };

    const onSubmit = () => {
      attemptedSubmit.value = true;
      canShowContactMethodError.value = true;
      canShowSelectedError.value = true;
      form.value
        .validate()
        .then(() => {
          if (!hasWeekDayErrors.value) {
            const contactInfo = formValues.data.contactInfo
              .filter(({ selected }) => selected)
              .map(({ method, address, id }) => ({ method, address, id }));

            const scheduleKeys = Object.keys(formValues.data.schedule);
            const schedules = [];
            const formSchedules = formValues.data.schedule;
            scheduleKeys.forEach((schedule) => {
              if (formSchedules[schedule].selected) {
                schedules.push(formSchedules[schedule].value);
              }
            });
            const minimumOrderAmount = parseInt(formValues.data.minimumOrderAmount.value);
            emit('on-submit', { schedules, contactInfo, minimumOrderAmount });
          }
        })
        .catch((err) => console.log(err));
    };

    const onDeliveryArrivalTimeSelect = (day, time) => {
      canShowSelectedError.value = false;
      formValues.data.schedule[day].value.deliveryArrivalTime.dayOfWeek = day;
      formValues.data.schedule[day].value.deliveryArrivalTime.timeOfDay = time;
    };

    const onDayOfWeekClicked = (day) => {
      canShowSelectedError.value = false;
      formValues.data.schedule[day].selected = !formValues.data.schedule[day].selected;
      dayOfWeekError.value = false;
    };

    const addContactMethod = (method) => {
      const lastIndex = formValues.data.contactInfo.reduce((acc, contact, idx) => {
        if (contact.method === method) acc = idx;
        return acc;
      }, -1);

      const newContact = {
        id: null,
        method,
        address: '',
        selected: true,
      };

      formValues.data.contactInfo.splice(lastIndex + 1, 0, newContact);
    };

    const handleCheckboxChange = (contact, index) => {
      const contactsOfSameMethod = formValues.data.contactInfo.filter((c) => c.method === contact.method);

      if (!contact.selected && contactsOfSameMethod.length > 1) {
        formValues.data.contactInfo.splice(index, 1);
      }
    };

    return {
      form,
      rules,
      weekDays,
      hoursList,
      selectedDays,
      weekDayErrors,
      mappedWeekDays,
      dayOfWeekError,
      currencySymbol,
      contactOptionsError,
      minimumOrderAmountError,
      validateMinimumOrderAmount,
      formValues: formValues.data,
      onCancel,
      onSubmit,
      validateEmpty,
      onDayOfWeekClicked,
      onMinOrderAmountChange,
      onDeliveryArrivalTimeSelect,
      onMinOrderAmountSelectedChange,
      isContactInfoWithValue,
      isTheLastContactFromTheSameMethod,
      addContactMethod,
      handleCheckboxChange,
    };
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';
::v-deep {
  div.el-form-item {
    margin-bottom: 0px;
  }

  span.el-checkbox__label,
  .el-checkbox__input.is-checked + span.el-checkbox__label {
    color: black;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  div.schedule.error {
    div.el-form-item__error {
      display: block;
    }
  }

  div.schedule,
  div.input {
    display: flex;
    div.el-form-item__error {
      display: none;
    }
  }

  div.contact-methods {
    div.el-form-item__content {
      display: flex;
      flex-direction: column;
    }
  }

  div.el-select.error {
    .el-input__inner {
      border: 1px solid red !important;
    }
  }

  div.input {
    .el-input__inner {
      direction: ltr;
      height: 2rem;
      width: 15rem;
    }
  }

  div.money-input {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
    &.error {
      .el-input__inner {
        border-top: 1px solid red !important;
        border-bottom: 1px solid red !important;
        border-inline-end: 1px solid red !important;
      }

      .el-input-group__prepend,
      .el-input-group__append {
        border-top: 1px solid red !important;
        border-bottom: 1px solid red !important;
        border-inline-end: 1px solid red !important;
      }
    }
    .el-form-item__content {
      line-height: normal;
    }
    .el-input__inner {
      transition: border-color 0s ease;
      direction: ltr;
      height: 2rem;
      padding: 0 5px;
    }
    .el-input-group--append {
      width: 5rem;
      .el-input__inner {
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .el-input-group__append {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      padding-left: 0.5rem;
      padding-right: 0.3rem;
      color: $typography-primary;
      vertical-align: middle;
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .el-input-group--prepend {
      width: 5rem;
    }
    .el-input-group__prepend {
      color: $typography-primary;
      padding-left: 0.5rem;
      padding-right: 0.3rem;
    }
  }

  div.error {
    span.el-checkbox__inner {
      border: 1px solid red;
    }
    span.el-checkbox__label {
      color: red;
    }
  }

  .el-select-dropdown {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .el-select-dropdown::-webkit-scrollbar {
    display: none;
  }
  .el-select-dropdown__item {
    font-weight: 400;
  }
  div.el-select-dropdown__wrap {
    overflow: auto;
  }
}

.order-term-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: $typography-primary;
  .contact-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .contact-info-title {
      font-weight: 550;
    }
    .contact-methods {
      .contact-method {
        display: flex;
        width: 30rem;
        height: 2.5rem;
        align-items: center;

        .checkbox {
          width: 8.25rem;
        }
        .input {
          align-items: flex-end;
        }
      }
    }
  }
  .extra-settings {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .extra-settings-title {
      font-weight: 550;
    }
    .min-order-amount {
      display: flex;
      height: 2.5rem;
      gap: 1.3rem;
      align-items: center;

      .checkbox {
        width: 10.3rem;
      }
      .money-input {
        align-items: flex-end;
      }
    }
  }
  .schedule {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .schedule-title {
      font-weight: 550;
    }
    .day-picker {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .day-of-week-checkbox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;
        width: 2rem;
        height: 2rem;
        border-radius: 6.25rem;
        border: 1px solid #e3e4e6;
        background: white;
        cursor: pointer;
        transition: all 0.2s;
      }

      div.error {
        color: red;
        border: 1px solid red;
      }

      div.selected {
        background: #306feb;
        color: white;
        border: none;
      }
    }
    .schedule-pickers {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .day {
        width: 8.25rem;
      }
      .delivery-arrival {
        color: $typography-primary;
        font-size: 0.875rem;
        font-weight: 550;
        [dir='ltr'] & {
          width: 9rem;
        }
        [dir='rtl'] & {
          width: 7.5rem;
        }
        margin-inline-end: 2rem;
        .hour-select {
          width: 5.5rem;
        }
      }
      .order-cut-off {
        color: $typography-primary;
        font-size: 0.875rem;
        font-weight: 550;
        display: flex;
        gap: 0.5rem;
        width: 13rem;
      }
      .schedule-picker,
      .schedule-picker-title {
        width: 32rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
.day-select {
  [dir='ltr'] & {
    width: 7rem;
  }
}

.add-button {
  [dir='ltr'] & {
    margin-left: 1rem;
  }
  [dir='rtl'] & {
    margin-right: 1rem;
  }
}
</style>

import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core/compositions/notification';

export const useCreateOrderTerms = (errorCallback) => {
  const { error } = useNotification();

  const { mutate, loading, onError, onDone } = useMutation(CREATE_ORDER_TERM);

  onError((err) => {
    console.error('useCreateOrderTerms', err);
    error();
    errorCallback();
  });

  return {
    createOrderTerms: mutate,
    loading,
    onDone,
  };
};

const CREATE_ORDER_TERM = gql`
  mutation CreateOrderTerms($businessId: ID!, $supplierId: ID!, $createPayload: CreateOrderTerms) {
    createOrderTerms(businessId: $businessId, supplierId: $supplierId, createPayload: $createPayload) {
      success
    }
  }
`;
